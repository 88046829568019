import { PencilIcon, TrashIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { Button, SvgIcon } from "@material-ui/core";
import { COLORS, compute } from "@ollie-sports/core";
import { translate } from "@ollie-sports/i18n";
import _ from "lodash";
import CoolerTable from "../../components/CoolerTable";
import { CoolTextInput } from "../../components/Inputs/CoolTextInput";
import getConfirm from "../../components/modals/getConfirm";
import { getBifrost } from "../../services/bifrost.service";
import { wrapPromiseWithLoader } from "../../utils/wrapPromiseWithLoader";
import { openOrgRegistrationQuestionGroupingAddEditModal } from "./AddEditOrgRegistrationQuestionGrouping";
import { ORG_PERMISSIONS, Org, OrgRegistrationQuestion, OrgRegistrationQuestionGrouping } from "@ollie-sports/models";
import { useState } from "react";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";

export function RegistrationQuestionGroupingsTable(p: {
  orgQuestionGroupings: OrgRegistrationQuestionGrouping[];
  orgQuestions: OrgRegistrationQuestion[];
  org: Org;
}) {
  const [searchInput, setSearchInput] = useState("");

  const orgQuestionsMap = _.keyBy(p.orgQuestions, a => a.id);

  return (
    <div className="mt-4">
      <CoolerTable
        items={p.orgQuestionGroupings}
        paginationOptions={{
          defaultPageSize: 25,
          pageSizeOptions: [25, 50, 100]
        }}
        defaultSortSettings={{ label: translate.common.Name, dir: "asc" }}
        noFilteredItemsMessage={translate({ defaultMessage: "No question groups found matching selected filters..." })}
        noItemsMessage={translate({
          defaultMessage:
            'Question groups let you easily assign multiple questions at once to a registration package. Click the "New Question Group" button above to create your first one.'
        })}
        rowButtons={
          compute.org.hasOrgPermission({
            accountId: getCurrentUserAccountId(),
            org: p.org,
            permission: ORG_PERMISSIONS.manageFinances
          })
            ? [
                //Edit
                {
                  getLabel: () => translate.common.Edit,
                  getIcon: () => <PencilIcon color={COLORS.blue_66} />,
                  type: "inline",
                  onClick: async item => {
                    openOrgRegistrationQuestionGroupingAddEditModal({
                      type: "edit",
                      org: p.org,
                      intialGrouping: item
                    });
                  }
                },
                //Delete
                {
                  getLabel: () => translate.common.Delete,
                  getIcon: () => <TrashIcon color={COLORS.red_66} />,
                  type: "inline",
                  onClick: async item => {
                    if (
                      !(await getConfirm({
                        confirmButtonColor: "red",
                        title: translate({ defaultMessage: "Delete Question Grouping?" }),
                        subtitle: translate({
                          defaultMessage:
                            "This question grouping will be removed from all registration packages it may be assigned to"
                        })
                      }))
                    ) {
                      return;
                    }

                    await wrapPromiseWithLoader(
                      getBifrost().orgRegistrationQuestionGrouping__client__delete.fetchClient({
                        id: item.id,
                        orgId: item.orgId
                      })
                    );
                  }
                }
              ]
            : []
        }
        columnDefs={[
          {
            label: translate.common.Name,
            getValue(item) {
              return item.name;
            },
            sortItems(items, dir) {
              return _.orderBy(items, a => a.name, dir);
            }
          },
          {
            label: translate({ defaultMessage: "Questions" }),
            getValue(item) {
              return (
                <div className="flex flex-wrap gap-1">
                  {_(item.questionIds)
                    .entries()
                    .orderBy(([qid, val]) => val.sortOrder)
                    .filter(([qid]) => !!orgQuestionsMap[qid])
                    .map(([qid]) => (
                      <div key={qid} className="text-white bg-gray-400 p-2 rounded-xl">
                        {orgQuestionsMap[qid].shortTitle}
                      </div>
                    ))
                    .value()}
                </div>
              );
            }
          }
        ]}
        getItemKey={item => item.id}
        filters={[
          {
            filterComponent: (
              <CoolTextInput
                placeholder={translate({ defaultMessage: "Filter..." })}
                value={searchInput}
                onChange={newVal => {
                  setSearchInput(newVal ?? "");
                }}
              />
            ),
            onFilter(items) {
              return searchInput
                ? items.filter(item =>
                    item.name.toLowerCase().replaceAll(" ", "").includes(searchInput.toLowerCase().replaceAll(" ", ""))
                  )
                : items;
            }
          },
          compute.org.hasOrgPermission({
            accountId: getCurrentUserAccountId(),
            org: p.org,
            permission: ORG_PERMISSIONS.manageFinances
          })
            ? {
                onFilter: a => a,
                filterComponent: (
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      openOrgRegistrationQuestionGroupingAddEditModal({
                        type: "create",
                        org: p.org
                      });
                    }}
                  >
                    <SvgIcon style={{ paddingRight: 6 }}>
                      <PlusCircleIcon />
                    </SvgIcon>
                    {translate({ defaultMessage: "New Question Group" })}
                  </Button>
                )
              }
            : null
        ]}
      />
    </div>
  );
}
